export const formatPlaces = (
  places: google.maps.places.AutocompletePrediction[],
) => {
  return places.map((place) => {
    return {
      id: place.place_id,
      matchingSubstring: place.matched_substrings,
      name: place.description,
      userFriendlyName: place.structured_formatting.main_text,
    };
  });
};
